import React from 'react';
import axios from 'axios';
import teamImages from '../../images/shirts/shirtsLookup.js';
import Shield from '../../images/logo_shield.png';
import Pitch from '../../images/pitch.png';

class InputPredictedLineups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: null,
      errorMessage: false,
      teamShortNames: [],
      typeShortNames: [],
      selectedTeamShortNames: new Set(),
      selectedTypeShortNames: new Set(),
      searchValue: '',
      lineupGWs: null,
      selectedLineupGW: (this.props.gw + 1).toString(),
      saveDraftStatus: null,
      realElementTypes: ['GKP', 'DEF', 'MID', 'FWD'],
      savedLineupGWs: null,
      selectedLoadLineupGW: null,
      postedBlog: null,
      playerModal: null, // Holds the el_id of the selected player
      tempXpts: '', // To handle editable xFPL points
      buttonState: 'default', // 'default', 'changing', 'changed'
    };
  }

  componentDidMount() {
    this.getGameweeks();
    this.getLineupsElements(this.state.selectedLineupGW);
    this.getSavedLineupDates();
    //this.getRankDates();
  }

  getGameweeks() {
    axios
      .post('/api/getGameweeks')
      .then((res) => {
        this.setState({ lineupGWs: res.data.gameweeks });
      })
      .catch((error) => this.setState({ errorMessage: true }));
  }

  getLineupsElements(gw) {
    axios
      .post('/api/getLineupElements', { gw: gw })
      .then((res) => {
        const elements = res.data.elements.map((element) => ({
          ...element,
          position: null,
          desired_position_rank: null,
          realElementType: element.type_short_name,
          position_rank: null,
        }));
        this.setState({ elements, errorMessage: false });
      })
      .catch((error) => this.setState({ errorMessage: true }));
  }

  setPredictedLineups() {
    this.setState({ saveDraftStatus: 'saving...' });
    axios
      .post('/api/insertPredictedLineups', {
        dfc_rank: this.state.elements,
        gw: this.state.selectedLineupGW,
      })
      .then((response) => {
        this.setState({ saveDraftStatus: response.data.Status });
        this.getSavedLineupDates();
      })
      .catch((error) => {
        console.error('Error posting data:', error);
        this.setState({ saveDraftStatus: 'Error saving lineups.' });
      });
  }

  loadPredictedLineups(gw) {
    axios
      .post('/api/loadPredictedLineups', {
        gw: this.state.selectedLoadLineupGW,
      })
      .then((response) => {
        this.setLoadedLineups(response.data.loadedLineups);
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  }

  getLineupsDates() {
    axios
      .post('/api/getLineupGameweeks')
      .then((response) => {
        this.setState({ lineupGWs: response.data.GWs });
        this.setState({ selectedLineupGW: response.data.GWs[0] });
      })
      .catch((error) => {
        console.error('Error getting data:', error);
      });
  }

  getSavedLineupDates() {
    axios
      .post('/api/getSavedLineupGameweeks')
      .then((response) => {
        this.setState({
          savedLineupGWs: response.data.GWs,
          selectedLoadLineupGW: response.data.GWs[0],
        });
      })
      .catch((error) => {
        console.error('Error getting data:', error);
      });
  }

  selectGW = (gw) => {
    this.setState({ selectedLineupGW: String(gw) });
    this.getLineupsElements(gw);
  };

  selectLoadGW = (gw) => {
    this.setState({ selectedLoadLineupGW: String(gw) });
  };

  setLoadedLineups = (data) => {
    const updatedElements = this.state.elements.map((element) => {
      const matchingElement = data.find(
        (loadedElement) => loadedElement.el_id === element.el_id
      );

      if (matchingElement) {
        return {
          ...element,
          position: matchingElement.position,
          realElementType: matchingElement.realElementType,
          position_rank: matchingElement.position_rank,
        };
      } else {
        return element;
      }
    });

    this.setState({ elements: updatedElements });
  };

  handleRankChange = (focusElement, desired_position) => {
    if (desired_position < 1 || isNaN(desired_position)) {
      desired_position = null;
    }

    const { elements } = this.state;

    let type;

    const updatedElements = elements.map((element) => {
      if (element.el_id === focusElement) {
        return {
          ...element,
          desired_position: desired_position,
          position: desired_position,
          position_rank: null, // Reset position_rank
        };
      }
      return element;
    });

    // Find the type of the focusElement
    const focusEl = elements.find((el) => el.el_id === focusElement);
    if (focusEl) {
      type = focusEl.realElementType;
    }

    this.setState({ elements: updatedElements }, () => {
      if (type) {
        this.moveRank(focusElement, 'up', type);
      }
    });
  };

  getTeamImage = (teamId, el_id) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? (
      <img
        className="shirt-icon"
        src={teamImage.src}
        alt={teamImage.team_id}
        onClick={() => this.handleTeamImageClick(el_id)} // Added onClick
        style={{ cursor: 'pointer' }} // Indicate clickable
      />
    ) : null;
  };

  handleDateChange(e) {
    this.setState({ selectedRankDate: e.target.value });
  }

  moveRank = (id, direction, type) => {
    // Find the selected element
    const selectedElement = this.state.elements.find((el) => el.el_id === id);
    if (!selectedElement) return; // Element not found

    const team = selectedElement.team;

    // Filter elements based on type, position, and team
    let elementsOfType = this.state.elements.filter(
      (el) => el.realElementType === type && el.position === 1 && el.team === team
    );

    // Sort elementsOfType based on position_rank and assign new ranks
    elementsOfType = elementsOfType.sort((a, b) => a.position_rank - b.position_rank);
    elementsOfType.forEach((el, index) => {
      el.position_rank = index + 1;
    });

    // Assign lowest available rank if position_rank is null or NaN
    if (selectedElement.position_rank === null || isNaN(selectedElement.position_rank)) {
      const ranks = elementsOfType
        .filter((el) => el.position_rank !== null && !isNaN(el.position_rank))
        .map((el) => el.position_rank);
      let lowestRank = 1; // Start from rank 1
      while (ranks.includes(lowestRank)) {
        lowestRank++;
      }
      selectedElement.position_rank = lowestRank;
    }

    const prevElement = elementsOfType.find(
      (el) => el.position_rank === selectedElement.position_rank - 1
    );
    const nextElement = elementsOfType.find(
      (el) => el.position_rank === selectedElement.position_rank + 1
    );

    if (direction === 'down' && prevElement) {
      prevElement.position_rank++;
      selectedElement.position_rank--;
    } else if (direction === 'up' && nextElement) {
      nextElement.position_rank--;
      selectedElement.position_rank++;
    }

    this.setState({ elements: this.state.elements });
  };

  handleRealElementTypeChange = (id, newType) => {
    this.setState(
      (prevState) => ({
        elements: prevState.elements.map((el) =>
          el.el_id === id
            ? { ...el, realElementType: newType, position_rank: null }
            : el
        ),
      }),
      () => this.moveRank(id, 'up', newType) // Ensure moveRank is called after state update
    );
  };

  postAsBlog = async () => {
    // Get HTML content of all specified divs
    const elements = document.querySelectorAll('#lineup.live-gw_details');
    let allHtmlContents = [];

    elements.forEach((element) => {
      const clonedElement = element.cloneNode(true);

      // Remove all onclick attributes
      clonedElement.querySelectorAll('[onclick]').forEach((elem) => {
        elem.removeAttribute('onclick');
      });

      // Remove divs containing buttons
      clonedElement.querySelectorAll('.player-single-buttons').forEach((buttonsDiv) => {
        buttonsDiv.remove();
      });

      allHtmlContents.push(clonedElement.outerHTML);
    });
    const htmlString = allHtmlContents.join('');

    // Send POST request with HTML content
    try {
      const response = await axios.post('/api/postLineupsBlog', {
        lineups: htmlString,
        gw: this.state.selectedLineupGW,
        token: localStorage.getItem('token'),
      });

      // Update state with received slug
      this.setState({ postedBlog: response.data.generated_slug });
    } catch (error) {
      console.error('Error posting as blog:', error);
    }
  };

  // Handle clicking on team image to open modal
  handleTeamImageClick = (el_id) => {
    this.setState({ playerModal: el_id }, () => {
      this.fetchPlayerDetails(el_id);
    });
  };

  // Fetch player details based on el_id
  fetchPlayerDetails = (el_id) => {
    // Find **all** players with the given el_id
    const players = this.state.elements.filter((el) => el.el_id === el_id);
    if (players.length > 0) {
      // Assuming all players with the same el_id have the same details
      const { gw1_xFPL_pts } = players[0];
      this.setState({
        tempXpts: gw1_xFPL_pts,
        buttonState: 'default',
      });
    }
  };

  // Close the modal
  handleModalClose = () => {
    this.setState({
      playerModal: null,
      tempXpts: '',
      buttonState: 'default',
    });
  };


 // Handle changes in xFPL points input with enhanced validation
handleXptsChange = (e) => {
  const newXpts = e.target.value;
  const gw1_xFPL_pts = this.state.tempXpts;

  // Regular expression to allow numbers with at most one decimal point
  const validNumberPattern = /^\d*\.?\d*$/;

  // Trim whitespace from the input
  const trimmedXpts = newXpts.trim();

  // Count the number of decimal points
  const decimalCount = (trimmedXpts.match(/\./g) || []).length;

  // Prevent multiple decimal points
  if (decimalCount > 1) {
    console.warn('Multiple decimal points are not allowed.');
    // Optionally, set an error message
    // this.setState({ errorMessage: 'Multiple decimal points are not allowed.' });
    return;
  }

  // Check if the input is either empty or matches the valid number pattern
  if (trimmedXpts === '' || validNumberPattern.test(trimmedXpts)) {
    this.setState({
      tempXpts: trimmedXpts,
      buttonState: trimmedXpts !== String(gw1_xFPL_pts) ? 'default' : 'grey',
      // Optionally, clear any previous error messages
      // errorMessage: '',
    });
  } else {
    // Optionally, handle invalid input (e.g., show an error message)
    console.warn('Invalid xFPL points input:', trimmedXpts);
    // Example: Set an error message in the state to display to the user
    // this.setState({ errorMessage: 'Please enter a valid number with at most one decimal point.' });
  }
};




  // Handle "Change xPts" button click
  handleChangeXpts = () => {
    const { playerModal, tempXpts } = this.state;

    // Update button state to 'changing'
    this.setState({ buttonState: 'changing' });

    // Find the first player with the matching el_id to get player_optaID and pulse_id
    const player = this.state.elements.find((el) => el.el_id === playerModal);
    if (!player) {
      console.error('Player not found!');
      this.setState({ buttonState: 'default' });
      return;
    }

    const { player_optaID, pulse_id } = player;

    axios
      .post('/api/changePlayerXpts', {
        player_optaID,
        pulse_id,
        gw1_xFPL_pts: tempXpts,
      })
      .then((response) => {
        // Update **all** elements with the matching el_id
        const updatedElements = this.state.elements.map((el) => {
          if (el.el_id === playerModal) {
            return { ...el, gw1_xFPL_pts: tempXpts };
          }
          return el;
        });
        this.setState({
          elements: updatedElements,
          buttonState: 'changed',
        });
      })
      .catch((error) => {
        console.error('Error changing xPts:', error);
        // Revert button state to 'default' on error
        this.setState({ buttonState: 'default' });
      });
  };

  render() {
    const {
      elements,
      selectedLineupGW,
      saveDraftStatus,
      lineupGWs,
      realElementTypes,
      savedLineupGWs,
      selectedLoadLineupGW,
      postedBlog,
      playerModal,
      tempXpts,
      buttonState,
    } = this.state;

    const sortedElements = elements
      ? elements
          .slice()
          .sort((a, b) => a.el_id - b.el_id)
      : [];

    // Derive player details directly from elements based on playerModal
    const selectedPlayers = playerModal
      ? elements.filter((el) => el.el_id === playerModal)
      : [];

    // Assuming all selectedPlayers have the same details
    const selectedPlayer = selectedPlayers.length > 0 ? selectedPlayers[0] : null;

    return (
      <div className="flex column align-center">
        {/* Gameweek Selectors */}
        {lineupGWs ? (
          <div className="flex align-center mb-20 g-5">
            <p className="mb-0">Select Gameweek</p>
            <select onChange={(e) => this.selectGW(e.target.value)}>
              {this.state.lineupGWs.map((gw, index) => (
                <option key={index} value={gw}>
                  {gw}
                </option>
              ))}
            </select>
            <button onClick={() => this.setPredictedLineups()}>
              Submit Lineups for GW {selectedLineupGW}
            </button>
            {saveDraftStatus ? <span>{saveDraftStatus}</span> : null}
          </div>
        ) : null}

        {savedLineupGWs && savedLineupGWs.length > 0 ? (
          <div className="flex align-center mb-20 g-5">
            <p className="mb-0">Get data from gameweek</p>
            <select
              value={selectedLoadLineupGW}
              onChange={(e) => this.selectLoadGW(e.target.value)}
            >
              {this.state.savedLineupGWs.map((gw, index) => (
                <option key={index} value={gw}>
                  {gw}
                </option>
              ))}
            </select>
            <button onClick={() => this.loadPredictedLineups()}>Load data</button>
          </div>
        ) : null}

        {/* Post as Blog Button */}
        <div className="mb-20">
          <button onClick={this.postAsBlog}>Post as Blog</button>
          {postedBlog ? <span>{postedBlog} posted</span> : null}
        </div>

        {/* Main Content */}
        <div>
          {sortedElements
            .sort((a, b) => {
              if (a.team < b.team) return -1;
              if (a.team > b.team) return 1;
              return 0;
            })
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.team === item.team && t.id === item.id && t.id != null
                )
            )
            .map((element, i) => {
              const teamId = element.team;
              const fixture = element.id;
              const teamElements = sortedElements.filter(
                (el) => el.team === teamId && el.id === fixture
              );
              const selectedTeamElements = teamElements.filter((el) => el.position === 1);
              const teamName = teamElements[0]?.team_name;
              const opponentName = teamElements[0]?.opponent_name; // new
              const ha = teamElements[0]?.h_a; // new
              const win = teamElements[0]?.win;
              const draw = teamElements[0]?.draw;
              const cs = teamElements[0]?.cs;
              const countPositionOne = teamElements.filter((el) => el.position === 1).length;

              return (
                <div key={i} className="flex flex-wrap g-10 flex-center mb-20">
                  <div
                    id="lineup"
                    className="live-gw_details text-center flex g-20 flex-wrap center-content space-around"
                  >
                    <div
                      key={teamId}
                      className="entry-card flex space-between column"
                      style={{
                        backgroundImage:
                          'url(https://draftfc.co.uk/wp-content/uploads/pitch.png)',
                        backgroundSize: 'cover',
                      }}
                    >
                      <div className="live-header g-10 mt-10">
                        <div className="text-left white">
                          <h4 className="mb-0 white">{teamName}</h4>
                          <p className="mb-0 italic white">
                            vs {opponentName} ({ha})
                          </p>
                        </div>
                        <div className="text-left white team-odds-holder">
                          <p className="mb-0 white team-odds">{'Win: ' + win.toFixed(0) + '%'}</p>
                          <p className="mb-0 white team-odds">{'Draw: ' + draw.toFixed(0) + '%'}</p>
                          <p className="mb-0 white team-odds">{'CS: ' + cs.toFixed(0) + '%'}</p>
                        </div>
                      </div>
                      <div className="start-players">
                        {['GKP', 'DEF', 'MID', 'FWD'].map((type, index) => (
                          <div key={index} className="player-row flex space-evenly mb-20 mt-10">
                            {selectedTeamElements
                              .filter((el) => el.realElementType === type)
                              .sort((a, b) => a.position_rank - b.position_rank)
                              .map((el) => (
                                <div
                                  key={el.el_id}
                                  className="onclick player-single flex-grow-1"
                                >
                                  <div className="flex g-5 player-single-buttons space-evenly">
                                    <button
                                      onClick={() => this.moveRank(el.el_id, 'up', type)}
                                    >
                                      ↑
                                    </button>
                                    <button
                                      onClick={() => this.moveRank(el.el_id, 'down', type)}
                                    >
                                      ↓
                                    </button>
                                  </div>
                                  {/* Pass el_id to getTeamImage */}
                                  <div>{this.getTeamImage(el.team, el.el_id)}</div>
                                  <div className="player_element_name text-overflow">
                                    {el.web_name}
                                  </div>
                                  <div className="player_element_points">
                                    {el.type_short_name}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mitch-draft-rank content-wrapper vh-60">
                      {teamElements
                        .sort((a, b) => {
                          // If a.position is 1 and b.position is not 1, sort a first
                          if (a.position === 1 && b.position !== 1) return -1;
                          // If b.position is 1 and a.position is not 1, sort b first
                          if (a.position !== 1 && b.position === 1) return 1;

                          // If both positions are 1 or both are not 1, sort by element_type
                          return a.element_type - b.element_type;
                        })
                        .map((element, index) => (
                          <div
                            className="flex mb-10 space-between align-center"
                            key={index}
                          >
                            <div className="flex g-5 align-center">
                              {element.position === 1 ? (
                                <div className="flex g-5 align-center minus">
                                  <button
                                    onClick={() =>
                                      this.handleRankChange(element.el_id, null)
                                    }
                                  >
                                    -
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  {countPositionOne < 11 && (
                                    <button
                                      onClick={() =>
                                        this.handleRankChange(element.el_id, 1)
                                      }
                                    >
                                      +
                                    </button>
                                  )}
                                </div>
                              )}
                              <span className={element.type_short_name}>
                                {element.type_short_name}
                              </span>
                              <span className="rostered">
                                {(element.rostered * 100).toFixed(1) + '%'}
                              </span>
                              <span>{element.web_name}</span>
                              <select
                                value={element.realElementType}
                                onChange={(e) =>
                                  this.handleRealElementTypeChange(
                                    element.el_id,
                                    e.target.value
                                  )
                                }
                              >
                                {realElementTypes.map((type, index) => (
                                  <option key={index} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {/* Modal Rendering */}
        {playerModal && selectedPlayer && (
          <div className="live-fixture_modal">
            <div className="modal-content">
              <span className="modal-close onclick" onClick={this.handleModalClose}>
                &times;
              </span>
              <div className="points-module">
                {/* Modal Title */}
                <div className="live-fixture_title">
                  <span className="live-fixture_name">
                    {selectedPlayer.web_name} - {selectedPlayer.team_short_name}
                  </span>
                </div>
                {/* Modal Details */}
                <div className="live-fixture_details">
                  <div className="flex column align-center g-5">
                    <p className="mb-0">xPts: </p>
                      <input
                        type="text"
                        value={tempXpts}
                        onChange={this.handleXptsChange}
                        className="mb-10 text-center"
                      />
                    <button
                      className={buttonState !== 'default' ? 'grey' : ''}
                      onClick={buttonState === 'default' ? this.handleChangeXpts : null}
                      disabled={buttonState !== 'default'}
                    >
                      {buttonState === 'default'
                        ? 'Change xPts'
                        : buttonState === 'changing'
                        ? 'Changing xPts'
                        : 'Changed xPts'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InputPredictedLineups;
